@import '/src/styles/kit';
$bannerHeight: 80vh;
.banner {
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: $bannerHeight;
  overflow: hidden;

  img, video {
    object-fit: cover;
    width: 100vw;
    height: $bannerHeight;
    position: absolute;
    max-height: 1080px;
    top: 0;
    left: 0;
  }
}