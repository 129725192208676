@import '/src/styles/kit';

.bio {
  margin-bottom: 4rem;

  @include desktop-and-up {
    padding: 0;
  }
  .hiddenLink a{
    color: white !important;
  }
  h2 {
    margin: 0;
  }

  h3 {
    margin: .5rem 0 1.5rem;
    color: $gray-600;
    font-style: italic;
    font-weight: normal;
  }

  p {
    line-height: 1.6rem;
  }

  img {
    float: left;
    margin: 0 $grid-gutter $grid-gutter 0;
    width: 100%;
    border-radius: 0 50% 0 50%;

    @include tablet-and-up {
      width: 30%;
    }
    @include desktop-and-up {
      width: 30%;
    }
  }
  &.reverse {

    @include tablet-and-up {
      img {
        float: right;
        margin: 0 0 2rem 2rem;

        border-radius: 50% 0 50% 0;
      }
    }
  }
}
