@import "./kit/index";

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-size: $font-size-root;
  font-family: $font-family-sans-serif;
}

h1 {
  margin: 2rem 0;
}

h2 {
  margin: 2rem 0 1rem;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.icon {
  display: inline-block;
  position: relative;
  top: .125rem;
  width: 1.25rem;
  margin-right: 0.5rem;
}
$borderRadius: 80%;
.rounded-top {
  overflow: visible;
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.rounded-up-bottom {
  border-bottom-left-radius: -1 * $borderRadius;
  border-bottom-right-radius: -1 * $borderRadius;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: visible;
}