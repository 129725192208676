@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


// Colors
// ------
$white:    #fff;
$gray-100: #f4f4f4;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:     #0B4F73;
$purple:   #431848;
$teal:     #00D4B2;

$red:      #df6179;

// Body
// ----
$body-color: $gray-900;


// Links
// -----
$link-decoration: none;

// Typography
// ----------
$font-family-sans-serif: "Lato", sans-serif;

$font-size-root: 16px;
$font-size-base: 1rem;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

// Grid
// ----
$min-width-tablet: 48rem;
$min-width-desktop: 62rem;

$container-width: 100%;
$container-width-tablet: 100%;
$container-width-desktop: 62rem;

$grid-columns: 12;
$grid-gutter: 1rem; // space between columns
