@import '/src/styles/kit';

.membershipCallout {
  $banner: $red;
  border: 1px solid $banner;
  width:100%;
  color: darken($banner, 20%);
  background-color: lighten($banner, 30%);
  top: 6rem;
  position: absolute;
  text-align: center;
  p {
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: 2rem;
    font-size: 1.2rem;
  }
  :global {
    .tagIcon {
      float: right;
      position: static;
      margin: 0 1rem;
      line-height: 3rem;
      @include tablet-and-up {
        line-height: 1rem;
      }
      svg {
        width: 1.5rem;
        fill: $blue;
      }
    }
  }
}
.homeBanner {
  position: relative;
  top: -25px;
  overflow: hidden;
  $top: 25%;
  .title {
    position: absolute;
    text-align: center;

    $titleWidth: 100%;
    top: $top;
    left: calc((100% - $titleWidth) / 2);
    width: $titleWidth;

    @include tablet-and-up {
      $titleWidth: 80%;
      top: $top;
      left: calc((100% - $titleWidth) / 2);
      width: $titleWidth;
    }
    @media(min-width: 60rem) {
      $titleWidth: 65%;
      top: $top;
      left: calc((100% - $titleWidth) / 2);
      width: $titleWidth;
    }
    //@include desktop-and-up {
    @media (min-width: 85rem) {
      $titleWidth: 60%;
      top: $top;
      left: calc((100% - $titleWidth) / 2);
      width: $titleWidth;
    }
    $text-shadow: 0 2px 10px rgba($black, .5);


    h1 {
      margin: 0;
      padding: 0;
      font-size: 3rem; // 5
      text-transform: capitalize;
      font-weight: $font-weight-black;
      color: $white;
      text-shadow: $text-shadow;
      @media(min-width: 80rem) {
        font-size: 3em;
      }
      @media(min-width: 90rem) {
        font-size: 3em;
      }
    }

    p {
      margin: 0;
      padding: 1rem 1.5rem 1.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      text-shadow: $text-shadow;
      color: $white;
      backdrop-filter: blur(1rem);

      @include tablet-and-up {
        font-size: 1.8rem;
        line-height: 2rem;
      }
    }
    a {
      display: none;
      margin-top: 2rem;
      padding: 1rem 2rem;
      color: $body-color;
      background-color: $teal;
      border-radius: 2rem;
      font-size: 1.5rem;
      @include tablet-and-up {
        display: inline-block;
      }
    }
  }
}
$borderRadius: 80%;
.rounded-top {
  overflow: visible;
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bottomBanner {

  color: $white;
  padding: 2rem;
  margin-top: -25px;
  border-top: 1px solid white;
  background-color: #1a3e63;
  height: 100%;
  min-height: 20vh;
  .bottomBannerText {
    font-size: 1.25rem;
    padding: 1rem;
    padding-top: 0;
    line-height: 2rem;
    text-align: center;
  }
}
.banner {
  .scrollIndicator {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .scrollLine {
    width: 30px;
    height: 4px;
    background-color: white;
    border-radius: 2px;
  }
}
.curvedTextContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 5rem;
  align-items: center;
  text {
    font-size: 4rem;
  }
  textPath {
    font-weight: 900;
    fill: white;
}
}
.curvedSvg{
  width: 100%;
  height: auto;
}
.navbar-toggler-dark {
  border-color: rgba(0, 0, 0, 0.5) !important;
}