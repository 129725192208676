@import '../../../styles/kit';

// Nav expand trigger on mobile devices
button.navExpand {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 1rem;
  margin-left: auto;  // push to the right
  z-index: 1001;


  .bar {
    position: relative;
    display: block;
    height: 2px;
    width: 1.25rem;
    background-color: $gray-700;
    border-radius: 1px;
    transition: transform 250ms ease;
  }

  .navExpandBar {
    @extend .bar;

    &:before {
      content: '';
      @extend .bar;
      position: absolute;
      top: -6px;
    }

    &:after {
      content: '';
      @extend .bar;
      position: absolute;
      top: 6px;
    }
  }

  &.navExpandIsOpen .navExpandBar {
    transform: rotate(-45deg);

    &:before {
      transform: rotate(90deg) translate(6px, 0);
    }

    &:after {
      top: 0;
      transform: rotate(90deg) translate(-2px, 0);
    }
  }
}

.nav {
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100vh;
  margin-left: auto;
  background-color: transparent;
  opacity: 0;
  transition: right 250ms ease-in-out, opacity 100ms ease-in-out;
  z-index: 1000;

  &.navIsOpen {
    right: 0;
    opacity: 1;
  }

  @include tablet-and-up {
    position: static;
    width: auto;
    height: auto;
    opacity: 1;
    background-color: transparent;
  }

  ul {
    list-style: none;
    margin: 2rem 0 0 0;
    padding: 4rem;

    @include tablet-and-up {
      margin: 0.8rem 0 0 0;
      padding: 0 0 0 2rem;
    }
  }

  li {
    margin-bottom: 1.3rem;

    @include tablet-and-up {
      display: inline-block;
      margin: 0 0 0 0.5rem;
    }
  }

  a {
    padding: 0.5rem 1rem;
    color: $body-color;
    border: 3px solid transparent;
    border-radius: 1.2rem;
    transition: border-color 250ms ease;

    @include tablet-and-up {
      font-size: 1rem;
    }

    &:active,
    &:focus,
    &:focus-visible,
    &:hover {
      border-color: $gray-500;
    }
  }
}

.active {
  border-color: $teal !important;
}

.inactive {
  border: none;
}

.navbar-toggler-dark {
  border-color: rgba(0, 0, 0, 0.5) !important;
}

.navExpand {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.navExpandIsOpen {
  // Add any styles for when the navigation is open
}

.hamburgerIcon {
  display: block;
  margin: auto;
}

// Ensure the menu is visible when open
.collapse.show {
  display: block !important;
}
