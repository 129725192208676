@import '../../styles/kit';


$logo-size: 3rem;

.header {
  position: absolute;
  width: 100%;
  max-width: 100vw;
  padding: 0;
  background: transparent;

  z-index: 1000;
  top: 0px;
  @include desktop-and-up {
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem;
  }

  .navWrapper {
    display: flex;
  }
}

.main {
  &.fullHeight {
    padding-top: 0;
  }
}

.logo {
  flex: 0 0 $logo-size;
  display: block;
  width: $logo-size;
  height: $logo-size;

  img {
    width: 100%;
  }
}

footer {
  font-size: .9rem;
  padding: 1rem 0 0;
  .footerLink {
    color: black;
    text-decoration: underline;
  }
  .footerContainer {
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
  }

  .footerColumn {
    display: grid;
    align-items: end;
    padding-top: 1rem;
    padding-bottom: 1rem;
    &:first-child {
      padding-bottom: 0;
    }
    @include tablet-and-up {
      height: 100%;
      padding-bottom: 1rem;
      &:first-child {
        padding-bottom: 1rem;
      }
    }
  }
}
.footerRowPadding {
  padding-top: 0.5rem;
  @include tablet-and-up {
    padding-top: 0rem;
  }
  padding-bottom: 0.5rem;

}
.newsletterContainer {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.instagramContainer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: $gray-300;
  h2 {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: $font-weight-black;
  }
  .post video {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px; /* Optional for rounded corners */
  }
}
@media (max-width: 768px) {
  .instagramContainer {
    display: none;
  }
}
.navbar-toggler-white {
  border-color: white; // Make the border white

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
}