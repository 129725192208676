@import '../../../styles/kit';


.location {
  .googleMap {
    border: 0;
    height:100%;
    height: 20rem;
    width:100%;
  }
  .locationCardWrapper {
    padding-bottom: $grid-gutter;
  }

  .locationCard {
    height: 100%;
    border: 1px solid $gray-300;
    padding: 1rem;
    border-radius: 1rem;
    a {
      color: $blue;
    }
    h3 {
      margin-top: 0;

      span {
        display: block;
        font-size: .9rem;
        font-weight: $font-weight-normal;
        font-style: italic;
        color: $gray-600;
      }
    }
    .busLink {
      &:before, &::after { 
        content: "\00a0 ";
      }
    }
  }

  .hsrIcon {
    display: inline-block;
    position: relative;
    top: 0.375rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    background-color: #F7C317;
    border-radius: 50%;
    padding: .5rem;
    box-sizing: content-box;

    img {
      position: relative;
      width: 100%;
      top: -5px;
    }
  }

  :global {
    .parkingIcon {
      top: .375rem;
      width: 1rem;
      height: 1rem;
      padding: .5rem;
      box-sizing: content-box;
      border-radius: 50%;
      background-color: $blue;

      svg {
        position: relative;
        //width: 100%;
        top: -5px;
        fill: $white;
      }
    }
  }

  address {
    display: flex;
    padding: 1rem;
    margin-bottom: 2rem;
    font-style: normal;
    font-size: .8rem;
    background-color: lighten($teal, 55%);
    border: 1px solid $teal;
    border-radius: 1rem;

    .iconWrapper {
      flex: 0 0 4rem;
      padding: 1.3rem 1rem 0;

      :global {
        .pin-icon {
          width: 2rem;

          svg {
            fill: darken($teal, 25%);
          }
        }
      }
    }

    .addressWrapper {
      flex: 1 0 auto;
    }

    p {
      margin: 0 0 .5rem;

      &.addressLine {
        margin: 0 0 .5rem;
        font-size: 1.5rem;
      }
    }
  }
}

.map {
  padding: 1rem;
  margin-top: 4rem;
  border: 1px solid $gray-300;
  border-radius: 1rem;

  h2 {
    margin: 0 0 1rem;
  }

  img {
    width: 100%;
  }
}

.gallery {
  padding: 4rem 0;

  h2 {
    text-align: center;
    font-weight: bolder;
    padding-bottom: 2rem;
  }

  .galleryItem {
    padding: 1rem;
    border: 1px solid $gray-400;
    border-radius: .5rem;
    img {
        min-height: 185px;
    }
  }
  img {
    border-radius: .3rem;
  }
}
