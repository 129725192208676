@mixin tablet-and-up {
  @media (min-width: $min-width-tablet) {
    @content
  }
}

@mixin desktop-and-up {
  @media (min-width: $min-width-desktop) {
    @content
  }
}