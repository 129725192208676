@import '/src/styles/kit';

.team {
  padding: 0;
  background-color: $gray-300;

  h2 {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: $font-weight-black;
  }

  .teamItem {
    padding-bottom: $grid-gutter;
    a {
      color: black;
      &:visited {
        color: black;
      }
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  h3{
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

  p {
    margin: 0;
    color: $gray-600;
    font-style: italic;
  }

  .image {
    overflow: hidden;
    border-top-left-radius: 70%;
    border-bottom-right-radius: 70%;

    height: 30rem;

    @include desktop-and-up {
      height: auto;
    }

    img {
      width: 100%;
    }
  }
 
  .button {
    display: inline-block;
    margin: 2rem 0 0;
    padding: .5rem 1rem;
    border-radius: 1.5rem;
    color: $body-color;
    border: 2px solid $teal;

    @include desktop-and-up {
      margin: 2rem 0 0;
    }
  }
}