@import '/src/styles/kit';
@import '/src/styles/global';
.community {
  height: 100%;
  background-color: #1a3e63;
  z-index: 1000;
  padding: 6rem 0;
  text-align: center;
  border-top: 2px solid white;
  h2 {
    margin: 0;
    font-size: 3rem;
    font-weight: $font-weight-black;
    text-transform: capitalize;
  }

  p {
    font-size: 1.1rem;
    text-align: left;
  }

  img {
    min-height: 300px;
    margin: auto;
  }
  .textContainer {
    background-color: rgba(#1a3e63, 0.01); /* Semi-transparent background */
    border-radius: 1rem; /* Rounded corners */
    padding: 2rem; /* Padding inside the container */
    text-align: left; /* Align text to the left */
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .activeTab {
    color: $teal;
  }
  .logoContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 80%;
    }

    .tabTitles {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;

      a {
        pointer-events: all;
        text-decoration: none;
        &.activeTab {
          color: $teal;
        }
        // Optional hover effect
        &:hover {
          transform: rotate(10deg);
          transition: transform 0.3s ease;
        }
      }

      text {
        fill: #fff;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
.bg-dark-blue {
  background-color: #1a3e63;
}