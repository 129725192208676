.experienceContainer {
    background-color: black;
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .highlight {
    color: teal; /* Adjust the color to match the "efit" text color */
  }
  
  .curvedTextContainer {
    width: 100%;
    height: auto;
  }
  
  path {
    stroke: white;
  }
  
  text {
    fill: white;
    font-size: 16px;
    text-anchor: middle;
    dominant-baseline: middle;
  }