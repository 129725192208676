@import '/src/styles/kit';


.homeBanner {
  position: relative;


  .title {
    position: absolute;
    text-align: center;

    $titleWidth: 90%;
    top: 30%;
    left: calc((100% - $titleWidth) / 2);
    width: $titleWidth;

    @include tablet-and-up {
      $titleWidth: 80%;
      top: 30%;
      left: calc((100% - $titleWidth) / 2);
      width: $titleWidth;
    }
    @media(min-width: 60rem) {
      $titleWidth: 60%;
      top: 30%;
      left: calc((100% - $titleWidth) / 2);
      width: $titleWidth;
    }
    //@include desktop-and-up {
    @media (min-width: 85rem) {
      $titleWidth: 35%;
      top: 30%;
      left: calc((100% - $titleWidth) / 2);
      width: $titleWidth;
    }
    $text-shadow: 0 2px 10px rgba($black, .5);


    h1 {
      margin: 0;
      padding: 0;
      font-size: 3rem; // 5
      text-transform: capitalize;
      font-weight: $font-weight-black;
      color: $white;
      text-shadow: $text-shadow;

      @include tablet-and-up {
        font-size: 5em;
      }

      &:after {
        content: '';
        position: relative;
        display: block;
        width: 100%;
        height: .8rem;
        margin-top: .5rem;
        background: linear-gradient(90deg, rgba(0,212,178,1) 8%, rgba(11,79,115,1) 52%, rgba(67,24,72,1) 100%);
      }
    }

    p {
      margin: 0;
      padding: 1rem 1.5rem 1.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      @include tablet-and-up {
        text-shadow: $text-shadow;
        color: $white;
      }
      color: $black;
      backdrop-filter: blur(1rem);

      @include tablet-and-up {
        font-size: 1.8rem;
        line-height: 2rem;
      }
    }
  }
}
