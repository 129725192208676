@import '/src/styles/kit';

.membership {
  margin-bottom: 2rem;

  .membershipCallout {
    border: 1px solid $teal;
    border-radius: 1rem;
    margin-bottom: 0.5rem;
    color: darken($teal, 20%);
    background-color: lighten($teal, 55%);

    :global {
      .tagIcon {
        float: left;
        position: static;
        margin: 0 1rem;
        line-height: 3rem;
        @include tablet-and-up {
          line-height: 1rem;
        }
        svg {
          width: 1.5rem;
          fill: $blue;
        }
      }
    }
  }

  .membershipCardWrapper {
    padding-bottom: $grid-gutter;
    margin-top: 2rem;
  }

  .membershipCard, .efitMembershipCard {
    height: 100%;
    border: 1px solid $gray-500;
    box-shadow: 0 .5rem .5rem rgba($black, .1);
    border-radius: 1rem 1rem 1rem .5rem;
    overflow: hidden;

    h2 {
      background-color: $gray-300;
      margin: 0;
      padding: 2rem 1rem;
      text-align: center;
      font-weight: $font-weight-bold;
      font-size: .9rem;

    }

    ul {
      list-style: none;
      padding: 1rem;
    }

    li {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $gray-300;
      line-height: 1.8rem;
      text-align: center;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      em {
        display: block;
        font-size: .8rem;
        color: $gray-600;
        line-height: 1rem;

        &.missing {
          color: $red;
        }
      }

      :global {
        .icon {
          display: inline-block;
          position: relative;
          width: .8rem;
          height: .8rem;
          margin-right: 0.2rem;
        }
        .check-icon {
          @extend .icon;
          top: 1px;
          fill: $teal;
        }

        .close-icon {
          @extend .icon;
          fill: $red;
        }
      }
    }
  }

  .efitMembershipCard {
    border: 2px solid $teal;

    h2 {
      background: linear-gradient(135deg, rgba(0, 212, 178, 1) 8%, rgba(11, 79, 115, 1) 52%, rgba(67, 24, 72, 1) 100%);;
      color: $white;
    }
  }
}

.formBox {
  padding: 3rem 0;
  background-color: $gray-300;

  form {
    label {
      font-weight: $font-weight-black;
      font-size: .8rem;
      margin-bottom: .5rem;
      display: inline-block;
      color: $body-color;
    }
    input, textarea, select {
      display: block;
      width: 100%;
      padding: .8rem 1rem;
      margin-bottom: 1rem;
      border: 1px solid $gray-500;
      border-radius: .5rem;
      background-color: $white;
      font-family: $font-family-sans-serif;
      transition: box-shadow 300ms;



      &:focus, &:focus-visible, &:active {
        border-color: $teal;
        box-shadow: 0 0 0 3px rgba($teal, .3);
        outline: none;
      }

      &[type="checkbox"] {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
      }

      &.error {
        border-color: $red;
      }
    }

    .error {
      margin: 0 0 .5rem;
      color: $red;
      font-size: .8rem;
    }

    button[type="submit"] {
      background-color: $teal;
      border-radius: 1.5rem;
      padding: .8rem 1rem;
      border: none;
      cursor: pointer;

      &:active {
        background-color: darken($teal, 5%);
      }
    }
    .consentCol {
      label {
        position: relative;
        top: -7px;
        margin-left: .5rem;
        font-weight: $font-weight-normal;
      }
    }
  }

  .successMessage, .errorMessage {
    padding: 1rem;
    border-radius: 1rem;
    border: 2px solid $teal;
    background-color: lighten(rgba($teal, 50%), 50%);

    h3 {
      margin: 0 0 .5rem;
    }
    p {
      margin: 0;
    }
  }

  .errorMessage {
    border-color: $red;
    background-color: lighten(rgba($red, 50%), 20%);
  }
}

.consentHelp {
  position: relative;
  display: inline-block;
  margin-left: .2rem;
  cursor: pointer;
  color: $blue;

  .consentBox {
    display: none;
    position: absolute;
    right: 0;
    bottom: 1.5rem;
    width: 30rem;
    border: 1px solid $gray-500;
    background-color: $gray-100;
    padding: 1rem 1rem .5rem;
    font-size: .8rem;
    border-radius: 1rem;
    font-weight: $font-weight-normal;
    line-height: 1.2rem;

    p {
      margin: 0 0 .5rem;
      color: $gray-700;
    }
  }

  &:hover {
    .consentBox {
      display: block;
    }
  }
}
.contactUsSubmit {
  font-size: 1.2rem;
  padding: 0.8rem 1.5rem !important;
}