@import './src/styles/kit';


.container {
  width: $container-width;
  margin: 0 auto;

  @include tablet-and-up {
    width: $container-width-tablet;
  }

  @include desktop-and-up {
    width: $container-width-desktop;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 $grid-gutter / 2;

  &.reverseRow {
    flex-direction: row-reverse;
  }
}

.col {
  flex: 1 0 0;
  padding: 0 $grid-gutter / 2;

  @for $index from 1 through $grid-columns {
    &.col#{$index} {
      @media (max-width: $min-width-tablet) {
        flex: 1 0 auto;
        width: calc(100% * $index / $grid-columns);
        max-width: calc(100% * $index / $grid-columns);
      }
    }

    &.colTablet#{$index} {
      @media (min-width: $min-width-tablet) and (max-width: $min-width-desktop) {
        flex: 1 0 auto;
        width: calc(100% * $index / $grid-columns);
        max-width: calc(100% * $index / $grid-columns);
      }
    }

    &.colDesktop#{$index} {
      @media (min-width: $min-width-desktop) {
        flex: 1 0 auto;
        width: calc(100% * $index / $grid-columns);
        max-width: calc(100% * $index / $grid-columns);
      }
    }
  }
}

