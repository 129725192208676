.scrollIndicator {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;  // Adjusted width
    height: 30px;  // Adjusted height // Adjusted border-radius
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .scrollBar {
    width: 20px;  // Adjusted width
    height: 6px;  // Adjusted height
    background-color: white;
    border-radius: 3px;
  }
